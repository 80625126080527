import styled from 'styled-components';

import { container, space } from 'theme/sizes';
import { toRem, breakpoints } from 'utils/mixins';

export const Wrapper = styled.div`
  position: relative;
  max-width: ${container.default};
  width: 100%;
  margin: 0 auto;

  @media ${breakpoints.laptop} {
    width: calc(100% - 36px);
  }
`;

export const Row = styled.div`
  display: block;
  align-items: center;
  overflow: hidden;

  .col {
    width: 100%;

    margin: 0 auto ${toRem(80)};

    @media ${breakpoints.laptop} {
      margin: ${toRem(60)} auto 0;
    }

    &.order1 {
      flex: ${(props) => props.imageRatio};
      aspect-ratio: ${(props) => props.imageRatio};
      position: relative;

      & > div {
        width: 100%;
        height: 100%;
      }
    }

    &.order2 {
      flex: ${(props) => props.image2Ratio};
      aspect-ratio: ${(props) => props.image2Ratio};
      position: relative;

      & > div {
        width: 100%;
        height: 100%;
      }
    }

    &--text {
      order: 1;
      padding: ${space.m} ${space.s};
    }
  }

  @media ${breakpoints.laptop} {
    display: flex;
    gap: ${space.m};

    .col {
      &--text {
        padding: ${space.l};
      }

      &.order1 {
        order: 1;
      }

      &.order2 {
        order: 2;
      }
    }
  }
`;
