import React from 'react';

import InView from 'react-intersection-observer';
import { motion } from 'framer-motion';

const AnimationWrapper = (props) => {
  //PROPS
  const { children } = props;
  //PROPS

  return (
    <InView threshold={0.4} triggerOnce>
      {({ inView, ref, entry }) => {
        return (
          <motion.div
            className="animation-wrapper"
            ref={ref}
            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
            initial={{ opacity: 0, y: 30 }}
            transition={{ duration: 0.5 }}
          >
            {children}
          </motion.div>
        );
      }}
    </InView>
  );
};

export default AnimationWrapper;
