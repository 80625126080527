import React from 'react';

import { GatsbyImage } from 'gatsby-plugin-image';
import InView from 'react-intersection-observer';
import { motion } from 'framer-motion';

import { Wrapper, Row } from './Banner.styles';

const Banner = (props) => {
  //PROPS
  const {
    data: {
      bannerImages: { image2, image },
    },
  } = props;
  //PROPS
  const imageRatio = image?.width / image?.height;
  const image2Ratio = image2?.width / image2?.height;

  return (
    <Wrapper>
      <Row imageRatio={imageRatio} image2Ratio={image2Ratio}>
        <InView threshold={0.4} triggerOnce>
          {({ inView, ref, entry }) => {
            return (
              <motion.div
                ref={ref}
                animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -30 }}
                initial={{ opacity: 0, y: 30 }}
                className="col order1"
                transition={{ duration: 0.5 }}
              >
                <GatsbyImage
                  image={image?.localFile?.childImageSharp?.gatsbyImageData}
                  alt={image?.altText}
                />
              </motion.div>
            );
          }}
        </InView>
        <InView threshold={0.4} triggerOnce>
          {({ inView, ref, entry }) => {
            return (
              <motion.div
                ref={ref}
                animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 30 }}
                initial={{ opacity: 0, y: 30 }}
                className="col order2"
                transition={{ duration: 0.5 }}
              >
                <GatsbyImage
                  image={image2?.localFile?.childImageSharp?.gatsbyImageData}
                  alt={image2?.altText}
                />
              </motion.div>
            );
          }}
        </InView>
      </Row>
    </Wrapper>
  );
};

export default Banner;
