import React from 'react';

import HTMLReactParser from 'html-react-parser';

import { Wrapper } from './TextBlock.styles';

const TextBlock = (props) => {
  //PROPS
  const { data, title, center, background } = props;
  //PROPS

  const hasContent = typeof (data.text || data) === 'string';
  
  return (
    <Wrapper center={center} background={background}>
      <div>
        {!!title && <h2>{title}</h2>}
        {!!hasContent && HTMLReactParser(data.text || data)}
      </div>
    </Wrapper>
  );
};

export default TextBlock;
