import styled from 'styled-components';

import { container } from 'theme/sizes';
import { toRem, breakpoints } from 'utils/mixins';

export const Wrapper = styled.div`
  position: relative;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  background-color: ${(props) => props.background ?? 'transparent'};

  & > ul {
    margin: auto;
    padding-top: ${toRem(32)};
    max-width: ${container.default};
    width: calc(100% - 48px);
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
    gap: ${toRem(24)};
  }

  .thumbnail {
    max-width: ${toRem(320)};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    & > div {
      flex: 1;
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    &-image {
      border-radius: ${toRem(24)};
      overflow: hidden;
      margin-bottom: ${toRem(12)};
      max-height: ${toRem(240)};
      max-width: ${toRem(240)};
      aspect-ratio: 1;
      height: 100%;
      width: 100%;
      flex: 1;

      & > div {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }

    &-info {
    }
  }

  p {
    font-size: ${toRem(18)};
    line-height: ${toRem(24)};
  }

  * {
    text-align: center;
  }

  @media ${breakpoints.tablet} {
    text-align: center;

    & > ul {
      padding-top: ${toRem(60)};
    }
  }
`;
