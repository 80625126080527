import React from 'react';

import InView from 'react-intersection-observer';
import { GatsbyImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';
import HTMLReactParser from 'html-react-parser';

import { Wrapper } from './Thumbnails.styles';

const Thumbnails = (props) => {
  console.log('props', props);

  //PROPS
  const {
    data: { thumbnail },
  } = props;
  //PROPS

  return (
    <Wrapper>
      <ul>
        {thumbnail.map((item, index) => {
          const { description, title, image } = item;
          return (
            <InView threshold={0.4} triggerOnce key={title + index}>
              {({ inView, ref, entry }) => {
                return (
                  <motion.li
                    ref={ref}
                    animate={
                      inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -30 }
                    }
                    initial={{ opacity: 0, y: 30 }}
                    className="thumbnail"
                    transition={{ duration: 0.5 }}
                  >
                    <div className="thumbnail-image">
                      <GatsbyImage
                        image={
                          image?.localFile?.childImageSharp?.gatsbyImageData
                        }
                        objectFit="contain"
                        alt={image?.altText}
                      />
                    </div>
                    <h4>{title}</h4>
                    {description && HTMLReactParser(description)}
                  </motion.li>
                );
              }}
            </InView>
          );
        })}
      </ul>
    </Wrapper>
  );
};

export default Thumbnails;
