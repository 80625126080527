import styled from 'styled-components';

import { container, space } from 'theme/sizes';
import { toRem, breakpoints } from 'utils/mixins';
import { white } from '../../theme/colors';

export const Wrapper = styled.div`
  position: relative;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  background-color: ${(props) => props.background ?? 'transparent'};

  & > div {
    margin: auto;
    padding-top: ${toRem(60)};
    max-width: ${container.xsmall};
    width: calc(100% - 48px);
  }

  a {
    text-decoration: underline;
  }

  p {
    font-size: ${toRem(18)};
    line-height: ${toRem(24)};
  }

  * {
    text-align: center;
  }

  @media ${breakpoints.tablet} {
    text-align: center;
  }
`;
