import React from 'react';

import { motion } from 'framer-motion';
import { graphql } from 'gatsby';

import DynaContent from 'components/dcontent';
import ContactForm from 'components/contact-form';

import { grey } from 'theme/colors';
import { pageAnims } from 'theme/animations';

const TemplateFabrica = (props) => {
  // PROPS
  const {
    lang,
    data: {
      page: { dcontent },
    },
  } = props;
  // PROPS

  return (
    <motion.div
      style={{ background: grey.default, minHeight: '100vh' }}
      variants={pageAnims}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <DynaContent rows={dcontent.dcontent} />
      <ContactForm lang={lang} />
    </motion.div>
  );
};

export const query = graphql`
  query Fabrica($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      id
      dcontent {
        dcontent {
          ... on WpPage_Dcontent_Dcontent_Banner {
            banner {
              imageFirst
              text
              title
              image {
                altText
                width
                height
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
          ... on WpPage_Dcontent_Dcontent_BannerImages {
            bannerImages {
              image {
                altText
                width
                height
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
              image2 {
                altText
                width
                height
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
          ... on WpPage_Dcontent_Dcontent_Video {
            videoBanner {
              mediaItemUrl
            }
          }
          ... on WpPage_Dcontent_Dcontent_Textblock {
            text
          }
        }
      }
    }
  }
`;

export default TemplateFabrica;
