import React from 'react';

import { GatsbyImage } from 'gatsby-plugin-image';
import HTMLReactParser from 'html-react-parser';

import { Wrapper } from './Relations.styles';

const Relations = (props) => {
  //PROPS
  const {
    data: { relations },
    setExtraInfo,
  } = props;
  //PROPS

  return (
    <Wrapper>
      <ul>
        {relations.map((e) => {
          if (e.projects) {
            const { nome, thumbnailImage, thumbnailText } = e.projects;

            return (
              <li key={nome} className="thumbnail">
                <div>
                  <div className="thumbnail-image">
                    <GatsbyImage
                      image={
                        thumbnailImage?.localFile?.childImageSharp
                          ?.gatsbyImageData
                      }
                      alt={thumbnailImage?.altText}
                    />
                  </div>
                  <div className="thumbnail-info">
                    <h4>{nome}</h4>
                    {thumbnailText && HTMLReactParser(thumbnailText)}
                  </div>
                </div>
                <button
                  className="button"
                  onClick={() => setExtraInfo(e.projects)}
                >
                  Saber mais
                </button>
              </li>
            );
          }

          if (e.noticias) {
            const { nome, thumbnailImage, thumbnailText } = e.noticias;

            return (
              <li key={nome} className="thumbnail">
                <div>
                  <div className="thumbnail-image">
                    <GatsbyImage
                      image={
                        thumbnailImage?.localFile?.childImageSharp
                          ?.gatsbyImageData
                      }
                      alt={thumbnailImage?.altText}
                    />
                  </div>
                  <div className="thumbnail-info">
                    <h4>{nome}</h4>
                    {thumbnailText && HTMLReactParser(thumbnailText)}
                  </div>
                </div>
                <button
                  className="button"
                  onClick={() => setExtraInfo(e.noticias)}
                >
                  Saber mais
                </button>
              </li>
            );
          }
        })}
      </ul>
    </Wrapper>
  );
};

export default Relations;
